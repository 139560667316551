/**
 * Created by sky on 2019/4/4.
 */
import axios from 'axios';


let baseURL
if (process.env.NODE_ENV === 'production') {
    baseURL = `../data/public/index.php`
}

const axios_config = {
    baseURL,
    timeout: 60 * 1000,
}

const axios_pro = axios.create(axios_config);

// 添加请求拦截器
axios_pro.interceptors.request.use(function (config) {
    if (localStorage['locale']) {
        config.headers['Accept-Language'] = localStorage['locale']
    }

    if (localStorage['admin_msg'] && localStorage['admin_msg'] != 'null' && localStorage['admin_msg'] != 'undefined') {
        let admin_msg = JSON.parse(localStorage['admin_msg']);
        config.headers.authtoken = admin_msg['user_token']
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// // 添加响应拦截器
axios_pro.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.data.code == 886 || response.data.code == 403) {
        loginExpireTodo();
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


// 登陆过期处理函数
function loginExpireTodo() {
    if (sessionStorage['login_from'] && sessionStorage['login_from'] == 'we7') {
        window.location.href = sessionStorage['login_from_url'];
    } else {
        if (process.env.NODE_ENV === 'production') {
            window.location.reload("../../../web")
        } else {
            let href = window.location.href
            let href_head = href.split("/#/")[0]
            let weizhui = href.split("/#/")[1]
            if (weizhui !== "login") {
                let login_url = href_head + "/#/login"
                window.location.replace(login_url)
            }
        }
    }
}


export default axios_pro;